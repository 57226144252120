@import "../../style/parameters";

.clients {
  padding: 45px 0;

  &__title {
    font-family: Nobel Bold, sans-serif;
    font-size: 33pt;
    color: $secondary-color;
    text-align: center;
    margin-bottom: 30px;
  }

  &__logo {
    padding: 90px 0;

    &__img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: auto;

      &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 130px;
      }
    }
  }
}
