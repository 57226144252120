.navigation {
  width: 1170px;
  margin: auto;

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $secondary-color;
    z-index: 500;
  }

  &__logo {
    width: 259px;
    img {

    }
  }

  &__collapse {

    &__nav {

      &__item {
        margin: 0 15px;

        &__link {
          font-family: Nobel Book, sans-serif;
          text-transform: uppercase;
          color: white !important;
          padding-left: 0;
        }
      }
    }
  }
}
