@import "../../style/parameters";

.footer {
  background-color: $yellow;
  padding: 45px 0 15px;

  @media all and (max-width: 1100px) {
    text-align: center;
  }

  &__title {
    font-family: Nobel Bold, sans-serif;
    font-size: 33pt;
    color: $secondary-color;
    text-align: center;
    margin-bottom: 45px;

    @media all and (max-width: 1100px) {
      margin-bottom: 0;
    }
  }

  &__contact {
    font-family: Nobel Book, sans-serif;
    font-size: 18pt;
    line-height: 21pt;
    color: $secondary-color;

    &__button {
      font-size: 11pt;
      font-family: Nobel Black, sans-serif;
      color: $yellow !important;
      background-color: $secondary-color !important;
      border-radius: 18px !important;
      padding: 11px 20px 10px !important;
      max-height: none !important;
      height: auto !important;
      margin-top: 15px;
      border: none !important;

      &:hover {
        background-color: lighten($secondary-color, 10) !important;
      }
    }

    &__data {

      @media all and (max-width: 1100px) {
        margin: 45px auto;
      }

      &__item {
        display: flex;
        flex-direction: row;
        padding-bottom: 30px;

        @media all and (max-width: 1100px) {
          // justify-content: center;
        }

        &__icon {
          margin-right: 15px;
          padding-top: 5px;
          min-width: 45px;

          & > img {
            width: 36px;
            height: auto;
          }
        }

        &__text {
          font-family: Nobel Regular, sans-serif;
          font-size: 12pt;
          color: $secondary-color;

          @media all and (max-width: 1100px) {
            text-align: left;
          }

          &__title {
            font-family: Nobel Bold, sans-serif;
          }

          &--link {
            font-size: 14pt;
            text-decoration: underline;
            position: relative;
            top: 5px;
          }
        }
      }
    }
  }

  &__copyright {
    text-align: center;
    font-family: Nobel Regular, sans-serif;
    padding-top: 30px;

    @media all and (max-width: 1100px) {
      padding-top: 45px;
    }

    &__item {
      font-size: 11pt;
      color: $secondary-color;
      padding: 0 15px;
      position: relative;

      @media all and (max-width: 1100px) {
        padding-bottom: 15px;
        display: inline-block;
      }

      &:after {
        content: '|';
        position: absolute;
        top: -3px;
        right: 0;

        @media all and (max-width: 1100px) {
         content: '';
        }
      }

      &:last-child {

        &:after {
          content: '';
        }
      }

      .ant-btn {
        text-decoration: underline !important;
        padding: 0;
        color: $secondary-color;
      }
    }
  }
}

.freebirdFormviewerViewHeaderHeader {
  display: none !important;
}
