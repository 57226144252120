@import "../../style/parameters";

.header {
  background-color: $secondary-color;
  padding: 120px 0 30px;

  @media all and (max-width: 1100px) {
    padding-top: 80px;
  }

  &__carousel {

    &__item {
      padding: 45px 0 30px;

      @media all and (max-width: 1100px) {
        text-align: center;
      }

      &__phrase {
        font-family: Nobel Book, sans-serif;
        font-size: 25pt;
        line-height: 28pt;
        color: white;

        &__strong {
          font-family: Nobel Bold, sans-serif;
        }
      }

      &__detail {

        @media all and (max-width: 1100px) {
          display: none;
        }

        &__img {
          display: inline-block;
          margin: 0 15px 0 auto;

          @media all and (max-width: 1100px) {
            margin: auto;
          }
        }
      }

      &__contact {
        font-family: Nobel Book, sans-serif;
        font-size: 18pt;
        line-height: 21pt;
        color: white;

        &__button {
          font-family: Nobel Black, sans-serif;
          color: $secondary-color !important;
          background-color: $yellow !important;
          border-radius: 18px !important;
          padding: 11px 20px 10px !important;
          max-height: none !important;
          height: auto !important;
          margin-top: 15px;
          border: none !important;

          &:hover {
            background-color: lighten($yellow, 10) !important;
          }
        }
      }
    }
  }
}
