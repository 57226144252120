@import "../../style/parameters";

.equipments-carousel {
  padding: 45px;
  position: relative;

  @media all and (max-width: 1100px) {
    padding-bottom: 0;
  }

  &__arrows {
    position: absolute;
    top: calc(50% - 30px);

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &__button {
      display: block;
      border: none;
      background-color: transparent;
      outline: 0 !important;
    }
  }

  &__item {
    padding-bottom: 75px;

    @media all and (max-width: 1100px) {
      padding: 0 15px 75px;
    }

    &__image {
      width: 100%;
      height: 300px;
      background-position: center center;
      background-size: cover;

      @media all and (max-width: 1100px) {
        margin-bottom: 15px;
      }
    }

    &__desc {

      p {
        font-family: Nobel Book, sans-serif;
        font-size: 14pt;
        color: white;
        padding: 0 15px;
      }

      &__icon {
        display: block;
        min-height: 110px;
        margin-bottom: 15px;
      }

      &__header {
        font-family: Nobel Bold, sans-serif;
        font-size: 20pt;
        display: block;
        margin-bottom: 15px;
      }

      &__text {
        font-size: 15pt;
        white-space: pre-wrap;
      }
    }
  }

  .slick-dots {

    li {
      button {
        background-color: white !important;
        opacity: 0.3 !important;
      }
    }

    li.slick-active {
      button {
        opacity: 1 !important;
      }
    }
  }
}
