@import "../../style/parameters";

.services {
  background-color: $primary-color;
  padding: 45px 0;

  &__title {
    font-family: Nobel Bold, sans-serif;
    font-size: 33pt;
    color: white;
    text-align: right;
    margin-bottom: 30px;

    @media all and (max-width: 1100px) {
      text-align: center;
    }
  }

  &__prhase {
    font-family: Nobel Book, sans-serif;
    font-size: 16pt;
    color: white;

    @media all and (max-width: 1100px) {
      text-align: center;
    }

    &__strong {
      font-family: Nobel Bold, sans-serif;
    }
  }
}
