@import "../../style/parameters";

.cookies-message {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(31, 42, 50, 0.95);
  width: 100%;
  padding: 20px 0 15px;
  z-index: 2500;

  &__title {
    font-size: 15pt;
    color: white;
  }

  &__message {
    margin-top: 5px;
    color: white;

    &__link {
      color: white;
      text-decoration: underline;

      &:hover {
        color: white;
        text-decoration: underline;
      }
    }
  }

  &__button {
    font-family: Nobel Black, sans-serif;
    color: $secondary-color !important;
    background-color: $yellow !important;
    border-radius: 18px !important;
    padding: 11px 20px 10px !important;
    max-height: none !important;
    height: auto !important;
    margin-top: 15px;
    border: none !important;

    &:hover {
      background-color: lighten($yellow, 10) !important;
    }

    &__wrapper {
      text-align: right;

      @media all and (max-width: 1100px) {
        text-align: left;
      }
    }
  }
}
