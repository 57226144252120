.whatsapp-share-button {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 2000;

  &__inner {

    &__img {
      width: 40px;
    }
  }
}
