@import "../../style/parameters";

.advanced-carousel {
  padding: 45px;
  position: relative;

  @media all and (max-width: 1100px) {
    padding-bottom: 0;
  }

  &__arrows {
    position: absolute;
    top: calc(50% - 30px);

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &__button {
      display: block;
      border: none;
      background-color: transparent;
      outline: 0 !important;
    }
  }

  &__item {
    padding-bottom: 45px;

    @media all and (max-width: 1100px) {
      text-align: center;
    }

    p {
      font-family: Nobel Book, sans-serif;
      font-size: 14pt;
      color: white;
      padding: 0 15px;
    }

    &__icon {
      display: block;
      height: 110px;
      margin-bottom: 15px;
      text-align: center;

      img {
        height: 90px;
        margin: auto;
      }

      @media all and (max-width: 1100px) {
        img {
          margin: auto;
        }
      }
    }

    &__header {
      font-family: Nobel Bold, sans-serif;
    }

    &__text {

    }
  }
}
