@font-face {
  font-family: 'Nobel Regular';
  src: url('/assets/font/Nobel-Regular.woff2') format('woff2'),
  url('/assets/font/Nobel-Regular.woff') format('woff'),
  url('/assets/font/Nobel-Regular.ttf') format('truetype'),
  url('/assets/font/Nobel-Regular.svg#Nobel-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nobel Bold';
  src: url('/assets/font/Nobel-Bold.woff2') format('woff2'),
  url('/assets/font/Nobel-Bold.woff') format('woff'),
  url('/assets/font/Nobel-Bold.ttf') format('truetype'),
  url('/assets/font/Nobel-Bold.svg#Nobel-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nobel Book';
  src: url('/assets/font/Nobel-Book.woff2') format('woff2'),
  url('/assets/font/Nobel-Book.woff') format('woff'),
  url('/assets/font/Nobel-Book.ttf') format('truetype'),
  url('/assets/font/Nobel-Book.svg#Nobel-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nobel Black';
  src: url('/assets/font/Nobel-Black.woff2') format('woff2'),
  url('/assets/font/Nobel-Black.woff') format('woff'),
  url('/assets/font/Nobel-Black.ttf') format('truetype'),
  url('/assets/font/Nobel-Black.svg#Nobel-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

