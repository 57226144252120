@import "../../style/parameters";

.equipments {
  padding: 45px 0;
  background-color: $secondary-color;

  @media all and (max-width: 1100px) {
    text-align: center;
  }

  &__title {
    font-family: Nobel Bold, sans-serif;
    font-size: 33pt;
    color: white;
    margin-bottom: 30px;
  }

  &__prhase {
    font-family: Nobel Book, sans-serif;
    font-size: 16pt;
    color: white;

    &__strong {
      font-family: Nobel Bold, sans-serif;
    }
  }
}
