@import "parameters";

$size: 10px;

.slick-dots {

  li {
    width: $size !important;
    height: $size !important;
    border-radius: 50% !important;

    button {
      background-color: white !important;
      opacity: 0.3 !important;
      width: $size !important;
      height: $size !important;
      border-radius: 50% !important;
    }
  }

  li.slick-active {
    button {
      opacity: 1 !important;
    }
  }
}
